@import '../../../variables';

.GridPlaylist {
  .GP-PlaceholderImage {
    height: 8em;
    margin-bottom: 0.3em;
  }

  .CreateNewPlaylistsBtn {
    color: $videoDetailstItems;
  }

  .GP-PlaceholderText {
    width: 50%;
    height: 1em;
  }

  .GP-Inner {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
    gap: 0.8em;

    @media (min-width: 1600px) {
      grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    }

    .GP-Item {
      &:hover {
        .ImageComponent {
          img {
            transform: scale(1.03);
          }
        }
      }

      .GP-ImageContainer {
        position: relative;
        overflow: hidden;

        .GP-Loading {
          position: absolute;
          inset: 0;
          z-index: 1;
          color: $white;
          font-size: 150%;
          background: rgba(0, 0, 0, 0.5);
          display: flex;

          i {
            margin: auto;
          }
        }

        .ImageComponent {
          aspect-ratio: 16 /9;

          img {
            cursor: pointer;
            transition: 0.2s ease-in-out;
          }
        }

        .GP-NowPlaying {
          border: 3px solid $videoDetailstItems;
          border-bottom: 0;
          position: absolute;
          inset: 0;
          z-index: 1;
          text-transform: uppercase;
          display: flex;
          align-items: flex-end;
          color: $white;

          span {
            flex: 1 1;
            background: $videoDetailstItems;
            padding: 3px 10px;
          }
        }
      }

      .GP-Info {
        .GP-Popup {
          position: relative;

          @media (min-width: $breakpoint-tablet) {
            .PlaylistsPlayerPopup {
              bottom: 50px;
              left: 50%;
              transform: translateX(-50%);
            }
          }

          .GP-CogIcon {
            cursor: pointer;
            color: $videoDetailstItems;
            padding: 0.5em 0.1em;

            &:hover {
              opacity: 0.7;
            }
          }
        }

        .GP-TitleContainer {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;

          .GP-Title {
            font: inherit;
            margin: 0;
            font-size: 100%;
            padding-right: 0.5em;
            line-height: 1.2;

            @media (min-width: $breakpoint-tablet) {
              font-size: 130%;
            }
          }
        }

        .GP-Total {
          font-size: 80%;
          opacity: 0.5;
        }
      }
    }
  }
}
